:root {
  --bg_primary: #E8D0B3;
  --text_primary: #86340A;
  --text_secondary: #C36839;
  --link_primary: #7EB5A6;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Inter",
    "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 90%;
  background-color: var(--bg_primary);
}
body p {
  font-size: 16px;
  line-height: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TODO: media queries for tablet and mobile font sizes */