.section-container {
    display: flex;
    justify-content: space-around;
    padding: 2%;
    margin: 0 auto;
    max-width: 100%;
}

.about-section {
    width: 45%
}

.teaching-goal-section {
    width: 45%
}

/* MEDIA QUERIES */
@media (max-width: 800px) {
    .section-container {
        display: flex;
        flex-direction: column;
    }
    .about-section {
        width: 100%;
    }
    .teaching-goal-section {
        width: 100%;
    }
}