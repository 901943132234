.footer-container {
    width: 100%;
}

.footer-content {
    padding: 2%;
}
.footer-content h4 {
    margin: 0;
}

/* MEDIA QUERIES */
@media (max-width: 800px) {
    .footer-container {
        margin-top: 40px;
        border-top: 1px solid var(--link_primary);
    }
}