.introduction-container {
    padding: 2%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    max-width: 100%;
}

.content-container {
    width: 50%;
}
.content-container h1 {
    font-weight: 500;
    font-size: 4rem;
    margin: 0;
}

.content-btns {
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.btn {
    text-decoration: none;
    background-color: var(--link_primary);
    padding: 2%;
    color: var(--text_primary);
    outline: 2px solid var(--text_primary);
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
}
.btn:hover {
    color: var(--bg_primary);
    outline: none;
}

.img-container {
    width: 40%;
    padding-top: 17px;
}
.img-container img {
    width: 100%;
    height: auto;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

/* MEDIA QUERIES */
@media (max-width: 800px) {
    .introduction-container {
        display: flex;
        flex-direction: column;
    }

    .content-container{
        width: 100%;
    }
    .content-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        gap: 20px;
    }
    
    .img-container {
        padding-top: 0;
        margin-top: 40px;
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
    }
}